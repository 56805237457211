<template>
  <div class="page my-page-wrap">
    <div class="inner">
      <div class="content">
        <div class="left">
          <img src="~@/assets/imgs/left.png" alt="" />
        </div>
        <div class="center main-content netinfo">
          <h1 class="net_tit">{{ detail.store_name }}</h1>
          <div class="net_pic flex flex-bet">
            <img :src="detail.store_pcimages1" alt="" v-if="detail.store_pcimages1">
            <img :src="detail.store_pcimages2" alt="" v-if="detail.store_pcimages2">
            <img :src="detail.store_pcimages3" alt="" v-if="detail.store_pcimages3">
            <img :src="detail.store_pcimages4" alt="" v-if="detail.store_pcimages4">
          </div>
          <div class="net_info">
            <div class="dd flex">
              <div class="label">联系电话:</div>
              <div class="info">{{ detail.telphone }}</div>
            </div>
            <div class="dd flex">
              <div class="label">联系地址:</div>
              <div class="info">
                {{ detail.address }}
                <a class="show-map" @click="jumpMap(detail)">
                  <span style="margin-right: 8px">查看地址</span>
                  <img src="~@/assets/imgs/address.png" alt="" />
                </a>
              </div>
            </div>
            <div class="dd flex">
              <div class="label">经销商服务:</div>
              <div class="info">{{ detail.store_service }}</div>
            </div>

            <div class="dd">
              <div class="label">店面简介:</div>
              <div class="info" style="margin-top: 10px; line-height: 1.8">
                {{ detail.store_introduction }}
              </div>
            </div>

            <div class="dd">
              <div class="label">店铺评价:</div>
              <div class="info" style="margin-top: 10px; line-height: 1.8">
                <p>{{ detail.store_evaluation }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <img src="~@/assets/imgs/right.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import http from "@/utils/http"
  export default {
    data() {
      return {
        detail: {},
      }
    },
    mounted() {
      this.getDetail(this.$route.query.id)
    },
    methods: {
      jumpMap(detail) {
        window.open("https://ditu.baidu.com/search/?querytype=s&wd=" + detail.full_address + detail.address)
      },
      getDetail(id) {
        http({
          url: "/api/store/info?store_id=" + id,
          data: {}
        }).then((res) => {
          console.log(res,1111)
          this.detail = res.data
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .flex {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
  }
  .netinfo {
    padding: 25px 0 65px;
    text-align: left;
    .net_tit {
      margin-top: 25px;
      color: #a20000;
      text-align: center;
      font-size: 31px;
      font-weight: normal;
    }
    .net_pic {
      padding: 45px 0;
      margin:0 -1%;
      img {
        width:23%;
        margin:0 1%;
        border-radius:10px;
        max-width: 100%;
      }
    }
    .net_info {
      .dd {
        font-size: 26px;
        line-height: 1.5;
        margin-bottom: 25px;
        color: #444;
        .label {
          color: #a20000;
          font-weight: bold;
        }
        .show-map {
          width: 115px;
          height: 45px;
          line-height:45px;
          cursor: pointer;
          text-align: center;
          background:#edeff6;
          display:inline-block;
          margin-left:15px;
          font-size:14px;
          color:#2f8cec;
          img{
            width:16px;
            height:16px;
          }
          &:hover{
            background:#e1e4ef;
          }
        }
      }
    }
  }
</style>
